import { useNavigate } from "react-router-dom";
import CustomButton from "../CustomButton";

const HomeAuthButtons = () => {
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate("/login");
  };

  const navigateToSignup = () => {
    navigate("/signup");
  };
  return (
    <div className="flex items-center gap-[20px] p-[14px]">
      {/* <CustomButton onClick={navigateToSignup}>أبدا الان</CustomButton> */}
      <CustomButton onClick={navigateToLogin}>تسجيل الدخول</CustomButton>
    </div>
  );
};

export default HomeAuthButtons;
